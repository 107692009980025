import * as React from 'react';
import { useState, useEffect } from 'react';
import Resources from '../lib/resources';
import Card from "./library/card";
import IconLeftArrow from './library/icons/iconLeftArrow';
import TextInput from './library/textInput';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { dispatchToProps as loginDP } from "../store/login-actions";
import MainLoader from './library/mainLoader';
import LockstepLogo from "./library/lockstepLogo";
import IconGlobe from './library/icons/iconGlobe';
import { isEmpty } from '../lib/utils';

const dispatchToProps = (dispatch: any) => ({
    ...loginDP(dispatch)
});


function ResetPassword(props: any) {

    let [emailAddr, setEmailAddr] = useState("");
    let [view, setView] = useState("request");
    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        console.log(props)
        console.log(props.match.params.view)
        if (props.match.params.view !== "request") {
            setView("update");
        }
    });

    const renderContent = () => {
        if (view === "request") {
            return (
                <React.Fragment>
                    <div className="reset-password-text-container">
                        <p>{Resources.ForgoutYourPassword}</p>
                        <p>{Resources.WellHelpYouResetAndGetBackOnTrack}</p>
                    </div>
                    <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.EmailAddress}
                        isValid={true}
                        placeholder={Resources.EmailAddress}
                        textValue={emailAddr}
                        inputOnChange={(e: any) => {
                            let value = e.target.value;
                            setEmailAddr(value);
                        }}
                    />
                    <div className="login-page-card-form-submit">
                        <button disabled={isEmpty(emailAddr)} onClick={() => props.sendResetEmail(emailAddr).then(() => {
                            props.history.push("/login");
                        })} className="button-primary-square">{Resources.ResetPassword}</button>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="reset-password-text-container">
                        <p>{Resources.PleaseEnterNewPassword}</p>
                    </div>
                    <div className="update-password-inputs">
                        <TextInput
                            width="23.2rem"
                            labelInline={false}
                            label={Resources.NewPassword}
                            isValid={true}
                            isPassword
                            placeholder={Resources.NewPassword}
                            textValue={password}
                            inputOnChange={(e: any) => {
                                let value = e.target.value;
                                setPassword(value);
                            }}
                        />
                        <TextInput
                            width="23.2rem"
                            labelInline={false}
                            label={Resources.ConfirmPassword}
                            isValid={true}
                            isPassword
                            placeholder={Resources.ConfirmPassword}
                            textValue={confirmPassword}
                            inputOnChange={(e: any) => {
                                let value = e.target.value;
                                setConfirmPassword(value);
                            }}
                        />
                    </div>
                    <div className="login-page-card-form-submit">
                        <button disabled={isEmpty(password) || isEmpty(confirmPassword)} onClick={() => props.sendResetEmail(emailAddr).then(() => {
                            props.history.push("/login");
                        })} className="button-primary-square">{Resources.SetNewPassword}</button>
                    </div>
                    <Link className="update-back-link" to="/login">{Resources.BackToLogin}</Link>
                </React.Fragment>
            )
        }
    }

    return (
        <div className="reset-password-container">
            <LockstepLogo />
            <Card className="reset-password-card">
                {props.loginStore.sendingResetEmail ? <div className="reset-password-loader">
                    <MainLoader fullScreen={true} />
                </div> :
                    <React.Fragment>
                        {view === "request" ? <Link to="/login"><button className="back-button"><IconLeftArrow height={22} /></button></Link> : null}
                        {renderContent()}
                    </React.Fragment>
                }
                <div className="language-button-container">
                    <IconGlobe height={14} className="language-button-icon" />
                    <span>{Resources.Language}</span>
                </div>
            </Card>
        </div >
    )
}

const storeToProps = (store: any) => {
    return {
        loginStore: store.login,
    };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ResetPassword));