import React, { Component } from "react";
import { isEmpty } from "../../lib/utils";
import Icons from "../../lib/icons";
import Resources from "../../lib/resources";

export default class Card extends Component {
  render() {
    let {
      children,
      maxWidth,
      className,
      title,
      headAboveCard,
      onClickEdit,
      editing,
      onCancel,
      onSave,
      description,
      type
    } = this.props;

    let isEditable = !isEmpty(onClickEdit) && !isEmpty(onSave);
    let cardHeader = () =>
      !isEmpty(title) && (
        <React.Fragment>
          <div className="card-head">
            <div className="card-headings">
              <h2 className="card-title">{title}</h2>
            </div>
            {isEditable &&
              (editing ? null : (
                <button className="crud-list-add-new" onClick={() => onClickEdit()}>
                  <span className={Icons.pencil + " pr-2"} />
                  <span>{Resources.Edit}</span>
                </button>
              ))}
          </div>
          <h3 className="card-description">{description}</h3>
        </React.Fragment>
      );
    return (
      <React.Fragment>
        {headAboveCard && cardHeader()}
        <div
          className={`card ${className ? className : ""} ${type ? "card-" + type : ""}`}
          style={{ maxWidth: maxWidth }}
          onClick={!isEmpty(this.props.onClick) ? this.props.onClick : undefined}
        >
          {!headAboveCard && cardHeader()}
          {children}
          {isEditable && editing && (
            <div className="card-bottom-buttons">
              {!isEmpty(onCancel) && (
                <button className="button-cancel mr-2" onClick={onCancel}>
                  {Resources.Cancel}
                </button>
              )}
              <button className="button-green" onClick={onSave}>
                {Resources.Save}
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
