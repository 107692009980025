import { actions } from "./login-actions";

const defaultNetworkSettings = {
  gettingMagicLink: false,
  gotMagicLink: false,
  failedGetMagicLink: false,
  magicLink: "",

  gettingCompanyLogo: false,
  gotCompanyLogo: false,
  failedGetCompanyLogo: false,
  companyLogo: "",

  gettingCustFromId: false,
  gotCustFromId: false,
  failedGetCustFromId: false,
  customer: {},

  registeringEmail: false,
  registeredEmail: false,
  failedRegisterEmail: false,

  loggingIn: false,
  loggedIn: false,
  loginFailed: false,

  sendingResetEmail: false,
  sentResetEmail: false,
  failedSendResetEmail: false
};

function loginReducer(state = defaultNetworkSettings, action: any) {
  switch (action.type) {
    case actions.GETTING_MAGIC_LINK:
      return {
        ...state,
        gettingMagicLink: true,
        gotMagicLink: false,
        failedGetMagicLink: false,
      };
    case actions.GOT_MAGIC_LINK:
      return {
        ...state,
        gettingMagicLink: false,
        gotMagicLink: true,
        failedGetMagicLink: false,
        magicLink: action.magicLink,
      };
    case actions.FAILED_GET_MAGIC_LINK:
      return {
        ...state,
        gettingMagicLink: false,
        gotMagicLink: false,
        failedGetMagicLink: true,
      };

    case actions.GETTING_COMPANY_LOGO:
      return {
        ...state,
        gettingCompanyLogo: true,
        gotCompanyLogo: false,
        failedGetCompanyLogo: false,
      };
    case actions.GOT_COMPANY_LOGO:
      return {
        ...state,
        gettingCompanyLogo: false,
        gotCompanyLogo: true,
        failedGetCompanyLogo: false,
        companyLogo: action.companyLogo,
      };
    case actions.FAILED_GET_COMPANY_LOGO:
      return {
        ...state,
        gettingCompanyLogo: false,
        gotCompanyLogo: false,
        failedGetCompanyLogo: true,
      };

    case actions.GETTING_CUST_FROM_ID:
      return {
        ...state,
        gettingCustFromId: true,
        gotCustFromId: false,
        failedGetCustFromId: false,
      };
    case actions.GOT_CUST_FROM_ID:
      return {
        ...state,
        gettingCustFromId: false,
        gotCustFromId: true,
        failedGetCustFromId: false,
        customer: action.customer,
      };
    case actions.FAILED_GET_CUST_FROM_ID:
      return {
        ...state,
        gettingCustFromId: false,
        gotCustFromId: false,
        failedGetCustFromId: true,
      };

    case actions.REGISTERING_NEW_EMAIL:
      return {
        ...state,
        registeringEmail: true,
        registeredEmail: false,
        failedRegisterEmail: false
      };
    case actions.REGISTERED_NEW_EMAIL:
      return {
        ...state,
        registeringEmail: false,
        registeredEmail: true,
        failedRegisterEmail: false,
        magicLink: action.magicLink
      };
    case actions.FAILED_REGISTER_NEW_EMAIL:
      return {
        ...state,
        registeringEmail: false,
        registeredEmail: false,
        failedRegisterEmail: true
      };

    case actions.LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginFailed: false
      };
    case actions.LOGGED_IN:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loginFailed: false
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginFailed: true
      };

    case actions.SENDING_RESET_EMAIL:
      return {
        ...state,
        sendingResetEmail: true,
        sentResetEmail: false,
        failedSendResetEmail: false
      };
    case actions.SENT_RESET_EMAIL:
      return {
        ...state,
        sendingResetEmail: false,
        sentResetEmail: true,
        failedSendResetEmail: false
      };
    case actions.FAILED_SEND_RESET_EMAIL:
      return {
        ...state,
        sendingResetEmail: false,
        sentResetEmail: false,
        failedSendResetEmail: true
      };

    default:
      return state;
  }
}

export default loginReducer;
