import * as React from 'react';
import Layout from './components/Layout';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { NotFound } from './components/notfound';
import ResetPassword from "./components/resetPassword";
import MagicLinkRequest from "./components/magicLinkRequest";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

function App(props: any) {
    return (
        <Layout>
            <Switch>
                <Route path='/notfound'>
                    <NotFound  />
                </Route>
                <Route path='/reset-password/:view'>
                    <ResetPassword />
                </Route>
                <Route path='/linkrequest/:accountKey/:companyId'>
                    <MagicLinkRequest />
                </Route>
                <Redirect to="/notfound" />
            </Switch>
        </Layout>
    )
}
export default withLDConsumer()(withRouter((App)));
