import React, { Component } from "react";

class ErrorIcon extends Component {
    render() {
        return (
            <div className="profile-update-done-icon-container">
                <svg width="375px" height="122px" viewBox="0 0 300 122" version="1.1">
                    <defs>
                        <rect id="path-1" x="0" y="0" width="1147" height="1024" rx="2"></rect>
                    </defs>
                    <g id="customer-portal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="3.5.3_error" transform="translate(-765.000000, -89.000000)">
                            <g id="Group-2" transform="translate(733.000000, 90.000000)">
                                <path d="M147.648148,24.8148148 C152.444843,24.8148148 156.333333,28.7033047 156.333333,33.5 C156.333333,38.2966953 152.444843,42.1851852 147.648148,42.1851852 L227.055556,42.1851852 C231.852251,42.1851852 235.740741,46.073675 235.740741,50.8703704 C235.740741,55.6670657 231.852251,59.5555556 227.055556,59.5555556 L254.351852,59.5555556 C259.148547,59.5555556 263.037037,63.4440454 263.037037,68.2407407 C263.037037,73.0374361 259.148547,76.9259259 254.351852,76.9259259 L230.777778,76.9259259 C225.981082,76.9259259 222.092593,80.8144158 222.092593,85.6111111 C222.092593,90.4078064 225.981082,94.2962963 230.777778,94.2962963 L238.222222,94.2962963 C243.018918,94.2962963 246.907407,98.1847862 246.907407,102.981481 C246.907407,107.778177 243.018918,111.666667 238.222222,111.666667 L57.0740741,111.666667 C52.2773787,111.666667 48.3888889,107.778177 48.3888889,102.981481 C48.3888889,98.1847862 52.2773787,94.2962963 57.0740741,94.2962963 L8.68518519,94.2962963 C3.88848986,94.2962963 0,90.4078064 0,85.6111111 C0,80.8144158 3.88848986,76.9259259 8.68518519,76.9259259 L58.3148148,76.9259259 C63.1115101,76.9259259 67,73.0374361 67,68.2407407 C67,63.4440454 63.1115101,59.5555556 58.3148148,59.5555556 L27.2962963,59.5555556 C22.499601,59.5555556 18.6111111,55.6670657 18.6111111,50.8703704 C18.6111111,46.073675 22.499601,42.1851852 27.2962963,42.1851852 L76.9259259,42.1851852 C72.1292306,42.1851852 68.2407407,38.2966953 68.2407407,33.5 C68.2407407,28.7033047 72.1292306,24.8148148 76.9259259,24.8148148 L147.648148,24.8148148 Z M259.314815,94.2962963 C264.11151,94.2962963 268,98.1847862 268,102.981481 C268,107.778177 264.11151,111.666667 259.314815,111.666667 C254.518119,111.666667 250.62963,107.778177 250.62963,102.981481 C250.62963,98.1847862 254.518119,94.2962963 259.314815,94.2962963 Z" id="Background" fill="#F4F3FF"></path>
                                <path d="M93.0555556,119.111111 C77.9802274,119.111111 65.7592593,107.167892 65.7592593,92.4351852 C65.7592593,77.7024781 77.9802274,65.7592593 93.0555556,65.7592593 C93.6966178,65.7592593 94.3325185,65.780856 94.9625375,65.8233456 C94.5247978,63.3867135 94.2962963,60.8774489 94.2962963,58.3148148 C94.2962963,35.0165804 113.183247,16.1296296 136.481481,16.1296296 C155.081479,16.1296296 170.869934,28.1672771 176.481244,44.8769701 C177.810737,44.7379591 179.160793,44.6666667 180.527778,44.6666667 C201.427665,44.6666667 218.37037,61.3316232 218.37037,81.8888889 C218.37037,101.418904 203.07862,117.532066 183.62963,119.084044 L183.62963,119.111111 L113.537471,119.111111 M107.939446,119.111111 L99.2981839,119.111111" id="Shape" stroke="#BEB6FF" stroke-width="2.5" fill="#FFFFFF" stroke-linecap="round"></path>
                                <circle id="Oval" stroke="#BEB6FF" stroke-width="2.5" cx="137.101852" cy="94.9166667" r="6.82407407"></circle>
                                <polyline id="Line" stroke="#BEB6FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" points="117.87037 80.6481481 126.555556 73.8325557 117.87037 67.3444074"></polyline>
                                <polyline id="Line" stroke="#BEB6FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" transform="translate(151.990741, 73.996278) scale(-1, 1) translate(-151.990741, -73.996278) " points="147.648148 80.6481481 156.333333 73.8325557 147.648148 67.3444074"></polyline>
                                <path d="M165.564798,28.537037 C157.488952,30.6743091 151.112396,36.9900274 148.888889,45.0304333" id="Oval" stroke="#BEB6FF" stroke-width="2.5" stroke-linecap="round" transform="translate(157.226843, 36.783735) scale(-1, 1) translate(-157.226843, -36.783735) "></path>
                                <circle id="Oval" stroke="#BEB6FF" stroke-width="2" cx="174.944444" cy="3.72222222" r="3.72222222"></circle>
                                <circle id="Oval" fill="#BEB6FF" cx="213.407407" cy="23.5740741" r="3.72222222"></circle>
                                <path d="M195.09735,17.0693162 L184.726963,27.4397041 M184.569316,17.0693162 L194.939704,27.4397041" id="Line-5" stroke="#BEB6FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M74.9454629,39.2026852 L67.5010185,46.6471297 M67.5010185,39.2026852 L74.9454629,46.6471297" id="Line-4" stroke="#BEB6FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <circle id="Oval" fill="#BEB6FF" cx="84.3703704" cy="27.2962963" r="3.72222222"></circle>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

export default ErrorIcon;