import React, { Component } from "react";

class IconAlertCircle extends Component {
  render() {
    let { className, height } = this.props;

    return (
      <svg viewBox="0 0 22 22" height={height} className={`icon ${className ? className : ""}`}>
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        >
          <circle cx="10" cy="10" r="10" />
          <path d="M10 6v4M10 14h.1" />
        </g>
      </svg>
    );
  }
}

IconAlertCircle.defaultProps = {
  height: 22,
};

export default IconAlertCircle;
