import util from "util";
import validator from "validator";
import moment from "moment";
import $ from "jquery";
import qs from "qs";
import numeral from "numeral";
import "numeral/locales";
// import { saveAs } from "file-saver";
import equal from "fast-deep-equal";

// import UBLPaymentTypeResources from "./ublPaymentTypeResources";
import Resources from "./resources";
import Icons from "./icons";
const onLocalHost = window.location.hostname === "localhost";

let config = require("../config/config.json");
if (!onLocalHost) {
  config.authEndpoint = window.location.origin.replace("app", "auth"); // https://auth.lockstep.network
  config.apiEndpoint = window.location.origin.replace("app", "api");
  config.emailDomain = window.location.hostname.replace("app.", "");
  config.appEndpoint = window.location.origin.replace("auth", "app");
}

export function isLocalHost() {
  return onLocalHost;
}

export function isStaging() {
  return config.apiEndpoint.split(".")[1].toLowerCase() === "stg";
}

export function isQA() {
  if (isLocalHost()) {
    return false;
  }
  return config.apiEndpoint.split(".")[1].toLowerCase() === "demo";
}

export function isLeading() {
  if (isLocalHost()) {
    return false;
  }
  return config.apiEndpoint.split(".")[1].toLowerCase() === "leading";
}

export function isTrailing() {
  if (isLocalHost()) {
    return false;
  }
  return config.apiEndpoint.split(".")[1].toLowerCase() === "trailing";
}

export function isProduction() {
  return config.apiEndpoint.split(".")[1].toLowerCase() === "lockstep";
}

export function replaceAt(arr, index, value) {
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
}

export function insertAt(arr, index, value) {
  return [...arr.slice(0, index), value, ...arr.slice(index)];
}

export function removeAt(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function getConfig() {
  return { ...config };
}

export function isAdmin() {
  return config.isAdminMode;
}

export function toggleAdmin() {
  if (isLocalHost()) {
    config.isAdminMode = !config.isAdminMode;
  }
}

export function updateConfig(item) {
  config = { ...config, ...item };
}

export function isEmpty(item) {
  if (util.isNullOrUndefined(item) || item === "undefined") return true;
  let type = typeof item;
  if (type === "object" && util.isArray(item)) type = "array";
  switch (type) {
    case "array":
      return item.length === 0;
    case "string":
      return item.trim().length === 0;
    case "object":
      for (let prop in item) {
        if (item.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(item) === JSON.stringify({});
    default:
      return false;
  }
}

export function getDisplaySize(sizeInBytes) {
  let size = sizeInBytes + 0;
  let kbSize = sizeInBytes / 1024;
  if (kbSize < 1) {
    return `${size}bytes`;
  }
  let mbSize = kbSize / 1024;
  if (mbSize < 1) {
    return `${kbSize.toFixed(0)}KB`;
  }
  let gbSize = mbSize / 1024;
  if (gbSize < 1) {
    return `${mbSize.toFixed(1)}MB`;
  }
  return `${gbSize.toFixed(2)}GB`;
}

export function getConversationSubject(subject) {
  let displaySubject = isEmpty(subject) ? "" : subject.trim();
  return displaySubject;
}

export function isGuid(guidText, version) {
  if (util.isString(guidText) === false) return false;
  if (isEmpty(guidText)) return false;
  let ver = version || "all";
  return validator.isUUID(guidText, ver);
}

export function compareDates(a, b) {
  let ma = moment(a);
  let mb = moment(b);
  if (ma.isSame(mb)) {
    return 0;
  } else if (ma.isBefore(mb)) {
    return -1;
  } else {
    return 1;
  }
}

export function compareStrings(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

export function removeLeading(char, from) {
  let to = from || "";
  while (to.length > 0 && to[0] === char) {
    to = to.substring(1);
  }
  return to;
}

const validEmailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export function isEmailValid(emailAddress) {
  return validEmailRegExp.test(emailAddress);
}

export function clone(item) {
  return JSON.parse(JSON.stringify(item));
}

export function deepCompare(a, b) {
  let strA = JSON.stringify(a || {});
  let strB = JSON.stringify(b || {});
  return compareStrings(strA, strB);
}

export function findIndex(array, match) {
  if (isEmpty(array)) {
    return -1;
  }
  let found = -1;
  for (let index = 0; index < array.length; index++) {
    let item = array[index];
    if (match(item)) {
      found = index;
      break;
    }
  }
  return found;
}

export function find(array, match) {
  if (isEmpty(array)) {
    return undefined;
  }
  let found = undefined;
  for (let index = 0; index < array.length; index++) {
    let item = array[index];
    if (match(item)) {
      found = item;
      break;
    }
  }
  return found;
}

export function parseDomain(email) {
  let domain = null;
  email.replace(/^(.*)@(.*)$/i, function ($0, $1, $2) {
    domain = $2;
  });

  return domain;
}

export function enableBSTooltips() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').on("show.bs.dropdown", function () {
    $(this).tooltip("hide");
    $(this).tooltip("disable");
  });
  $('[data-toggle="tooltip"]').on("hide.bs.dropdown", function () {
    $(this).tooltip("enable");
  });
}

export function disableBSTooltips() {
  $('[data-toggle="tooltip"]').off();
}

export function getQueryParameters(url) {
  let u = url;
  if (u.startsWith("http")) {
    u = url.split("?").splice(1).join("?");
  }
  return qs.parse(u, { ignoreQueryPrefix: true });
}

export function formatCurrency(amount, currency = "$") {
  if (isNaN(amount)) {
    return amount;
  }
  return `${currency}${numeral(amount).format("0,0.00")}`;
}

export function coalesceUserName(user = {}) {
  return user.fullName || user.givenName || user.familyName || user.email || "";
}

// 10,000 ticks from C# === one millisecond
export function ticksToReadableTime(ticks, returnNumber) {
  return millisecondsToReadableTime(ticks / 10000, returnNumber);
}

export function millisecondsToReadableTime(milliseconds, returnNumber) {
  if (milliseconds === 0) {
    if (returnNumber) {
      return "0 seconds";
    }
    return "---";
  }

  moment.duration.fn.hackedHumanize = function (thres) {
    let save = {};
    for (let k in thres) {
      save[k] = moment.relativeTimeThreshold(k);
    }
    for (let k in thres) {
      moment.relativeTimeThreshold(k, thres[k]);
    }
    let res = this.humanize();
    for (let k in thres) {
      moment.relativeTimeThreshold(k, save[k]);
    }
    return res;
  };

  let duration = new moment.duration(milliseconds);

  return duration.hackedHumanize({ s: 120, m: 120, h: 120, d: 210, M: 1200 });
}

export function millisecondsToReadableTimeHardCutoff(milliseconds, returnNumber) {
  if (milliseconds === 0) {
    if (returnNumber) {
      return "0 seconds";
    }
    return "---";
  }

  moment.duration.fn.hackedHumanize = function (thres) {
    let save = {};
    for (let k in thres) {
      save[k] = moment.relativeTimeThreshold(k);
    }
    for (let k in thres) {
      moment.relativeTimeThreshold(k, thres[k]);
    }
    let res = this.humanize();
    for (let k in thres) {
      moment.relativeTimeThreshold(k, save[k]);
    }
    return res;
  };

  let duration = new moment.duration(milliseconds);

  return duration.hackedHumanize({ s: 60, m: 60, h: 24, d: 30, M: 12 });
}

export function getInvoiceNum(invoiceId) {
  let invoiceNum;
  if (isEmpty(invoiceId)) {
    return null;
  }
  if (invoiceId.SchemeID === "CCLI") {
    invoiceNum = invoiceId.Value.substring(invoiceId.Value.lastIndexOf(":") + 1) || "";
  } else {
    invoiceNum = invoiceId.Value;
  }

  return invoiceNum;
}

export function createKey() {
  return [...arguments].join(":");
}

export function includes(item, value) {
  return item.indexOf(value) >= 0;
}

export function createNewEvent(eventName) {
  let event;
  if (typeof Event === "function") {
    event = new Event(eventName);
  } else {
    event = document.createEvent("Event");
    event.initEvent(eventName, true, true);
  }
  return event;
}

export function uniqueId() {
  return Math.random().toString(36).substr(2, 16);
}

export function getFileType(fileName) {
  let fileType = "";
  let lastIndex = fileName.lastIndexOf(".");
  if (lastIndex) {
    fileType = fileName.substr(lastIndex + 1).toLowerCase();
  }

  const imgTypes = ["jpeg", "jpg", "png", "apng", "gif", "svg", "bmp", "bmp ico", "png ico"];
  const txtTypes = ["txt", "rtf"];
  const wordTypes = ["doc", "docx"];

  if (fileType === "pdf") {
    return "pdf";
  } else if (includes(imgTypes, fileType)) {
    return "img";
  } else if (includes(wordTypes, fileType)) {
    return "word";
  } else if (includes(txtTypes, fileType)) {
    return "txt";
  } else {
    return "none";
  }
}

export function getFileNameIconClasses(fileName) {
  switch (getFileType(fileName)) {
    case "pdf":
      return "attachment-pdf " + Icons.filePdf;
    case "txt":
      return "attachment-text " + Icons.fileAlt;
    case "word":
      return "attachment-word " + Icons.fileWord;
    case "img":
      return "attachment-image " + Icons.fileImage;
    default:
      return "attachment-text " + Icons.fileAlt;
  }
}

export function containsHtml(str) {
  return /<[a-z][\s\S]*>/i.test(str);
}

export function htmlToText(htmlString) {
  let doc = new DOMParser().parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

export function getReportsTicks(data) {
  let max = Math.max(null, ...data);
  let maxMoment = moment.duration(max / 10000);
  let maxUnit = "seconds";

  if (maxMoment.asDays() > 1) {
    maxUnit = "days";
  } else if (maxMoment.asHours() > 1) {
    maxUnit = "hours";
  } else if (maxMoment.asMinutes() > 1) {
    maxUnit = "minutes";
  }
  max = Math.ceil(maxMoment.as(maxUnit));
  let ticks = [];
  for (let i = 0; i <= 1; i += 0.25) {
    ticks.push(Math.round(max * i));
  }
  ticks = [...new Set(ticks)];
  ticks = ticks.map((tick) => moment.duration(tick, maxUnit).asMilliseconds() * 10000);

  return ticks;
}

export function spliceString(str, index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index;
    if (index < 0) {
      index = 0;
    }
  }

  return str.slice(0, index) + (add || "") + str.slice(index + count);
}

export function formatTaskLabel(date, isCompleted) {
  if (isCompleted) {
    return {
      labelText: Resources.Completed,
      labelClassName: "task-completed",
    };
  }

  const daysDiff = moment().diff(moment.utc(date).local(), "days").toString();

  let labelClassName = "overdue";

  const labelText = moment
    .utc(date)
    .local()
    .calendar(null, {
      sameDay: function (now) {
        labelClassName = "due-today";
        return `[${Resources.DueToday}]`;
      },
      nextDay: function (now) {
        labelClassName = "due-later";
        return `[${Resources.DueTomorrow}]`;
      },
      nextWeek: function (now) {
        labelClassName = "due-later";
        return `[${Resources.Due}] dddd`;
      },
      lastDay: `[${Resources.OverdueByOneDay}]`,
      lastWeek: function (now) {
        return `[${Resources.OverdueBy(daysDiff)}]`;
      },
      sameElse: function (now) {
        if (this.isBefore(now)) {
          return `[${Resources.OverdueBy(daysDiff)}]`;
        }
        labelClassName = "due-later";
        return `[${Resources.Due}] L`;
      },
    });

  return { labelText, labelClassName };
}

export function formatDimensionalDate(toFormat) {
  let date = moment(toFormat);

  if (date.isSame(moment(), "d")) {
    return date.format("LT");
  } else if (date.isSame(moment(), "year")) {
    return date.format("MMM D");
  } else {
    return date.format("l");
  }
}

export function formatDate(dateToFormat, isUTC = false) {
  let date = isUTC ? moment.utc(dateToFormat) : moment(dateToFormat);

  return date.format("ll");
}

export function getNextBusinessDay(toBusinessDay) {
  // If given date is not a business day then it will return the next business day
  let date = moment(toBusinessDay);
  let dayOfWeek = date.day();
  switch (dayOfWeek) {
    case 6:
      return date.add(2, "day").format();
    case 0:
      return date.add(1, "day").format();
    default:
      return toBusinessDay;
  }
}

export function capStringAtLength(string, length) {
  return string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
}

export function stringToSentenceCase(string) {
  /*eslint-disable */
  return string.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
  /*eslint-enable */
}

export function updateSelectedRows(index, selectedRows, dataLength) {
  let newSelectedRows;
  if (Array.isArray(index)) {
    newSelectedRows = [...selectedRows];
    index.forEach((i) => {
      newSelectedRows = updateSelectedRows(i, newSelectedRows, dataLength);
    });
    return newSelectedRows;
  } else if (index === "all") {
    if (selectedRows.length === dataLength) {
      newSelectedRows = [];
    } else {
      let newArray = [];
      for (let i = 0; i < dataLength; i++) {
        newArray.push(i);
      }
      newSelectedRows = newArray;
    }
    newSelectedRows.sort((a, b) => a > b);

    return newSelectedRows;
  } else {
    newSelectedRows = [...selectedRows];
    let indexInSelectedRows = findIndex(selectedRows, (row) => equal(row, index));
    if (indexInSelectedRows > -1) {
      newSelectedRows.splice(indexInSelectedRows, 1);
    } else {
      newSelectedRows.push(index);
    }

    newSelectedRows.sort((a, b) => a - b);
    return newSelectedRows;
  }
}

// export function getReadableFromUBLPaymentType(paymentType) {
//   let values = UBLPaymentTypeResources.CodeList.SimpleCodeList.Row;
//   let readableUBL = find(values, ublType => ublType.Value[0].SimpleValue === paymentType) || {};
//   return (readableUBL.Value || [{}, {}])[1].SimpleValue;
// }

export function updateSort(sortBy, currentSortBy, currentSortDirection, updateFunction) {
  if (currentSortBy !== sortBy) {
    updateFunction(sortBy, "desc");
  } else if (currentSortDirection === "desc") {
    updateFunction(sortBy, "asc");
  } else {
    updateFunction(sortBy, "desc");
  }
}

export function getAttatchedDocumentLedgerContent() {
  return `<ubl:AttachedDocument xmlns:ubl="urn:oasis:names:specification:ubl:schema:xsd:AttachedDocument-2" xmlns:cac="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2" xmlns:cbc="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2"  xmlns:xs="http://www.w3.org/2001/XMLSchema"><cbc:UUID>${generateGUID()}</cbc:UUID></ubl:AttachedDocument>`;
}

export function generateGUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export function openUrlForDownload(url, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    let save = document.createElement("a");
    save.href = url;
    save.target = "_blank";
    let filename = url.substring(url.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
      document.location = save.href;
      // window event not working here
    } else {
      let evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    let _window = window.open(url, "_blank");
    _window.document.close();
    // _window.document.execCommand("SaveAs", true, fileName || url);
    _window.close();
  }
}

// export function saveBlobAsDownload(blob, fileName) {
//   saveAs(new Blob([blob]), fileName);
// }

export function onBlurCheckFocusable(cb) {
  setTimeout(() => {
    const activeIsChildOfFocusable = Array.from(document.getElementsByClassName("focusable")).some((el) =>
      el.contains(document.activeElement)
    );
    const activeisFocusable = includes(document.activeElement.getAttribute("class") || "", "focusable");
    if ((activeIsChildOfFocusable || activeisFocusable) && document.hasFocus()) {
      return;
    }

    cb();
  }, 1);
}

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export function sortObjects(objects, sortBy, sortDirection, sortByType = "string") {
  if (sortByType === "date") {
    return [...objects].sort((a, b) => {
      if (a[sortBy] > b[sortBy]) {
        return moment().compareDates(a, b);
      } else {
        return -moment().compareDates(a, b);
      }
    });
  }
  return [...objects].sort((a, b) => {
    if (a[sortBy] > b[sortBy]) {
      if (sortDirection === "desc") {
        return 1;
      }
      return -1;
    } else {
      if (sortDirection === "desc") {
        return -1;
      }
      return 1;
    }
  });
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
}

export function generateUUID() {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
