import React, { Component } from "react";

class IconLeftArrow extends Component {
  render() {
    let { className, height } = this.props;
    return (
      <svg
        className={`icon ${className ? className : ""}`}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
    );
  }
}

IconLeftArrow.defaultProps = {
  height: 24,
};

export default IconLeftArrow;
