import React, { Component } from "react";

class IconGlobe extends Component {
  render() {
    let { className, height, style } = this.props;

    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        height={height}
        className={`icon ${className ? className : ""}`}
        style={style}
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z" />
      </svg>
    );
  }
}

IconGlobe.defaultProps = {
  height: 22,
};

export default IconGlobe;
