//This function uses the window.strings object that is created populated by the localization reducer when the user switched language preferences

export function getString(languageConstant, defaultValue) {
  let stringLibrary = window.strings || {};
  let result = stringLibrary[languageConstant];
  if (window.localStorage && window.localStorage.getItem("nolangui")) {
    result = `[${languageConstant}]`;
  }
  if (!result) {
    result = defaultValue;
  }
  return result;
}

// "format {0}, {1} {2}"
// formatString(getString(), ['thing', 'thinger', 'thinger3']);
// "format {thinger_1} {thinger_2} {thinger_3} "
// formatString(getString(...), {thinger_1: 'wat', thinger_2: 'wat2', thinger_3: 'wat3'});

export function formatString(str, args, delimOpen, delimClose) {
  if (arguments.length) {
    const open = delimOpen || "{";
    const close = delimClose || delimOpen || "}";
    for (let key in args) {
      if (Object.prototype.hasOwnProperty.call(args, key)) {
        str = str.replace(new RegExp(`\\${open}${key}\\${close}`, "gi"), args[key]);
      }
    }
  }
  return str;
}
